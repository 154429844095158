
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDSrskdKAnoF0DBXUMFgAkuOOX35oIl05E",
  authDomain: "warringah-mode-engineers.firebaseapp.com",
  projectId: "warringah-mode-engineers",
  storageBucket: "warringah-mode-engineers.appspot.com",
  messagingSenderId: "418733719795",
  appId: "1:418733719795:web:99e51c3e3f960006e67786",
  measurementId: "G-ZR533E117T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };