import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Container, TextField, Button, Typography, CircularProgress, Grid, Paper } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const EditProject = () => {
  const { id: projectId } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({
    title: '', description: '', featureImage: '', gallery: [], modelId: '', url: '', maker: '',
    status: 'draft' // Default to 'draft'
  });
  const [imageUpload, setImageUpload] = useState({file: null, type: ''});
  const [imagePreview, setImagePreview] = useState({ featureImage: '', gallery: [] });
  const [loading, setLoading] = useState(false);
  const meta = ["modelId", "url", "maker"]; // Meta fields

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      const docRef = doc(db, 'projects', projectId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setProject(docSnap.data());
        setImagePreview({ featureImage: docSnap.data().featureImage, gallery: docSnap.data().gallery || [] });
      } else {
        console.log("No project found");
      }
      setLoading(false);
    };

    fetchProject();
  }, [projectId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject(prev => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    setImageUpload({file: e.target.files[0], type: e.target.title});
  };

  const uploadImage = () => {
    if (!imageUpload.file) return;
    const storageRef = ref(storage, `projects/${projectId}/${imageUpload.file.title}`);
    const uploadTask = uploadBytesResumable(storageRef, imageUpload.file);

    uploadTask.on('state_changed',
      (snapshot) => {
        setLoading(true);
        // Optionally handle progress here
      }, 
      (error) => {
        console.error("Error uploading image:", error);
        setLoading(false);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          if (imageUpload.type === 'featureImage') {
            setProject(prev => ({ ...prev, featureImage: downloadURL }));
            setImagePreview(prev => ({ ...prev, featureImage: downloadURL }));
          } else { // Assuming type is 'gallery'
            const updatedGallery = [...project.gallery, downloadURL];
            setProject(prev => ({ ...prev, gallery: updatedGallery }));
            setImagePreview(prev => ({ ...prev, gallery: [...prev.gallery, downloadURL] }));
          }
          setLoading(false);
        });
      }
    );
  };

  const saveProject = async () => {
    setLoading(true);
    await updateDoc(doc(db, 'projects', projectId), project);
    navigate(`/projects/${projectId}`);
    setLoading(false);
  };


  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>Edit Project</Typography>
      <form noValidate autoComplete="off">
        <TextField fullWidth label="Title" name="title" value={project.title} onChange={handleInputChange} margin="normal" />

        <InputLabel>Status</InputLabel>
  <Select
  fullWidth
    label="Status"
    name="status"
    defaultValue="draft"
    value={project.status ?project.status:"draft" }
    onChange={handleInputChange}
  >
    <MenuItem value="draft">Draft</MenuItem>
    <MenuItem value="public">Public</MenuItem>
  </Select>

        <TextField fullWidth label="Description" name="description" multiline rows={4} value={project.description} onChange={handleInputChange} margin="normal" />
        {meta.map(field => (
          <TextField key={field} fullWidth label={field.charAt(0).toUpperCase() + field.slice(1)} name={field} value={project[field]} onChange={handleInputChange} margin="normal" />
        ))}
        <input type="file" name="featureImage" onChange={handleImageChange} />
        <Button onClick={uploadImage} disabled={loading}>Upload Feature Image</Button>
        <input type="file" name="gallery" onChange={handleImageChange} />
        <Button onClick={uploadImage} disabled={loading}>Upload to Gallery</Button>
        <br/>
        {loading && <CircularProgress />}
        <Button variant="contained" color="primary" onClick={saveProject} disabled={loading}>Save</Button>
      </form>
      {imagePreview.featureImage && (
        <div>
          <Typography variant="h6">Feature Image Preview:</Typography>
          <img src={imagePreview.featureImage} alt="Feature" style={{ width: '100%', maxHeight: '300px', objectFit: 'contain' }} />
        </div>
      )}
      {imagePreview.gallery.length > 0 && (
        <div>
          <Typography variant="h6">Gallery Preview:</Typography>
          <Grid container spacing={2}>
            {imagePreview.gallery.map((url, index) => (
              <Grid item xs={4} key={index}>
                <Paper>
                  <img src={url} alt={`Gallery ${index}`} style={{ width: '100%', maxHeight: '100px', objectFit: 'cover' }} />
                </Paper>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
    </Container>
  );
};

export default EditProject;
