import React, { useState, useEffect } from 'react';
import { db } from '../firebase/config'; // Adjust the import path as needed
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { Container, Typography, List, ListItem, ListItemText, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  useEffect(() => {
    const fetchContacts = async () => {
      const querySnapshot = await getDocs(collection(db, 'contacts'));
      const contactsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        read: doc.data().read || false, // Assume all messages are unread by default if not specified
      }));
      setContacts(contactsData);
    };

    fetchContacts();
  }, []);

  const handleClickOpen = (contact) => {
    setSelectedMessage(contact);
    setOpen(true);
    // Mark the message as read
    markAsRead(contact.id);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const markAsRead = async (contactId) => {
    // Update the contact's read status in the state
    setContacts(contacts.map(contact => 
      contact.id === contactId ? { ...contact, read: true } : contact
    ));
  
    // Update the read status in Firestore
    const contactRef = doc(db, 'contacts', contactId);
    try {
      await updateDoc(contactRef, { read: true });
      console.log("Document successfully updated!");
    } catch (error) {
      // Handle the error here
      console.error("Error updating document: ", error);
    }
  };
  

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>Contact Submissions</Typography>
      <List>
        {contacts.map((contact) => (
          <React.Fragment key={contact.id}>
            <ListItem 
              alignItems="flex-start" 
              button 
              onClick={() => handleClickOpen(contact)}
              sx={{ bgcolor: contact.read ? 'inherit' : '#ffcccc' }} // Light red for unread messages
            >
              <ListItemText
                primary={contact.name}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="text.primary">
                      {contact.email}
                    </Typography>
                    {` - ${contact.message.substring(0, 30)}${contact.message.length > 30 ? '...' : ''}`}
                  </>
                }
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Message from {selectedMessage?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>{selectedMessage?.email}</DialogContentText>
          <DialogContentText>{selectedMessage?.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Contacts;
