// src/App.js or where you define your routes
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme'; // Adjust the import path as needed
import Dashboard from './pages/Dashboard';
import ProjectsList from './pages/Projects/List';
import ProjectsItem from './pages/Projects/Item';
import Contacts from './pages/Contacts';
import Page404 from './pages/Page404';
import Login from './pages/Login';
import Users from './pages/Users';
import { useAuth } from './contexts/AuthContext'; // Adjust path as needed
import { CircularProgress, Box } from '@mui/material';
import AppBar from './components/AppBar';

import { signOut } from 'firebase/auth'; // Import signOut if not part of useAuth
import { auth } from './firebase/config'; // Adjust the import path as needed

// Other imports...

const App = () => {
  const { currentUser, permissions, loading } = useAuth();

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

      
  if (!currentUser ) 
  {
//    signOut(auth).catch((error) => console.error("Error logging out:", error));

    return (
      <Login/>
    );
  }


  
    
  if ( permissions === null) 
  {
    signOut(auth).catch((error) => console.error("Error logging out:", error));

    return (
      <Login/>
    );
  }

  /*
  if (!currentUser || permissions === null) {
    // Logout the user if permissions are null
    signOut(auth).catch((error) => console.error("Error logging out:", error));
    return <Navigate to="/login" replace />;
  }
*/
  
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <AppBar/>
      
      <Routes>
        
          <Route path="/" element={<Dashboard />} />
          <Route path="/projects" element={<ProjectsList />} />
          <Route path="/projects/:id" element={<ProjectsItem />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/users" element={<Users />} />
          <Route path='*'  element={<Page404/>}  status={404}/>


      </Routes>
    </Router>
    </ThemeProvider>
  );
};

export default App;
