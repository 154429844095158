// src/pages/Dashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography, Grid } from '@mui/material';
import { useAuth } from '../contexts/AuthContext'; // Adjust import path as needed

const Dashboard = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Use the useAuth hook to access the current user

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        404
      </Typography>
      <Typography variant="h6">
        can not find. 
      </Typography>
      
    </Container>
  );
};

export default Dashboard;
