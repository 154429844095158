// src/pages/Users.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase/config'; // Adjust the import path as needed
import { collection, getDocs, updateDoc, doc, addDoc } from 'firebase/firestore';
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem, Typography, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newUser, setNewUser] = useState({ email: '', permissions: 'viewer' });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, 'users'));
    const usersData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(usersData);
    setLoading(false);
  };

  const handlePermissionsChange = async (userId, newPermissions) => {
    setLoading(true);
    const userDoc = doc(db, 'users', userId);
    await updateDoc(userDoc, { permissions: newPermissions });
    await fetchUsers(); // Re-fetch users to reflect the updated permissions
    setLoading(false);
  };

  const handleAddUser = async () => {
    setLoading(true);
    await addDoc(collection(db, 'users'), newUser);
    setOpen(false); // Close the dialog
    await fetchUsers(); // Re-fetch users to include the new user
    setLoading(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>User Management</Typography>
      {loading && <CircularProgress />}
      <Button startIcon={<AddIcon />} onClick={handleOpen} variant="outlined" sx={{ my: 2 }}>
        Add User
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Permissions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                <Select
                  value={user.permissions}
                  onChange={(e) => handlePermissionsChange(user.id, e.target.value)}
                  fullWidth
                  disabled={loading}
                >
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="editor">Editor</MenuItem>
                  <MenuItem value="viewer">Viewer</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <Select
            margin="dense"
            id="permissions"
            value={newUser.permissions}
            onChange={(e) => setNewUser({ ...newUser, permissions: e.target.value })}
            fullWidth
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="editor">Editor</MenuItem>
            <MenuItem value="viewer">Viewer</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddUser}>Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Users;
