// src/pages/Dashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Typography, Grid } from '@mui/material';
import { useAuth } from '../contexts/AuthContext'; // Adjust import path as needed

const Dashboard = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Use the useAuth hook to access the current user

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>
      <Typography variant="h6">
        Welcome, {currentUser ? currentUser.email : 'Guest'}!
      </Typography>
      <Box sx={{ margin: '20px 0' }}>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => navigate('/projects')}>
              Manage Projects
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => navigate('/contacts')}>
              View Contacts
            </Button>
          </Grid>
          {currentUser && currentUser.role === 'admin' && (
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => navigate('/users')}>
                User Management
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* Further dashboard content like stats or recent activity could go here */}
    </Container>
  );
};

export default Dashboard;
