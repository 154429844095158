// src/contexts/AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase/config'; // Adjust the import path as needed
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {

    /*
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });*/

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Fetch user permissions from Firestore
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setCurrentUser(user);
          setPermissions(userDoc.data().permissions); // Assuming permissions are stored in the 'permissions' field
        } else {
          // Handle case where user is authenticated but permissions document does not exist
          console.log("User document doesn't exist");
          setPermissions(null); // Or handle as needed
        }
      } else {
        setCurrentUser(null);
        setPermissions(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, permissions, loading }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
