// src/lib/firebase/auth.js

import { auth } from './config'; // Import auth from your Firebase config file
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';


// Sign up (register) a new user
const signUp = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    return userCredential.user;
  } catch (error) {
    console.error("Error signing up:", error.message);
    throw error; // Re-throw the error to be handled where signUp is called
  }
};

// Sign in an existing user
const signIn = async (email = null, password =null) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch (error) {
      console.error("Error signing in:", error.message);
      throw error; // Re-throw the error to be handled where signIn is called
    }
  };

  // Function to initiate Google sign-in
const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // The signed-in user info
      const user = result.user;
      console.log('Google Auth User:', user);
      // You can perform additional user setup here, if necessary
      // For example, checking/creating a user record in Firestore
      return user;
    } catch (error) {
      console.error("Error signing in with Google:", error);
      // Handle errors here, such as displaying a notification
      throw error; // Re-throw if you want to handle this error outside of the function
    }
  };

// Sign out the current user
const signOutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out:", error.message);
    throw error; // Re-throw the error to be handled where signOutUser is called
  }
};

// Monitor authentication state
const onAuthStateChange = (callback) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      callback({ loggedIn: true, user });
    } else {
      callback({ loggedIn: false });
    }
  });
};

export {signInWithGoogle, signUp, signIn, signOutUser, onAuthStateChange };
