// src/pages/AdminProjects.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config'; // Adjust the import path as needed
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { Container, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Table, TableBody, TableCell, TableHead, TableRow, Typography, CircularProgress, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AdminProjects = () => {
  const [projects, setProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [newProject, setNewProject] = useState({ title: '', description: '' , status:"draft" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, 'projects'));
    const projectsData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    setProjects(projectsData);
    setLoading(false);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddProject = async () => {
    setLoading(true);
    const docRef = await addDoc(collection(db, 'projects'), newProject);
    setLoading(false);
    setOpen(false);
    setNewProject({ title: '', description: '' }); // Reset form
    navigate(`/projects/${docRef.id}`); // Navigate to the newly created project page
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Admin - Project List</Typography>
      <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
        Add Project
      </Button>
      {loading && <CircularProgress />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project) => (
            <TableRow key={project.id}>
              <TableCell>{project.title}</TableCell>
              <TableCell>{project.status}</TableCell>
              <TableCell>
                <Button onClick={() => navigate(`/projects/${project.id}`)}>View</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Project Title"
            type="text"
            fullWidth
            variant="outlined"
            value={newProject.title}
            onChange={(e) => setNewProject({ ...newProject, title: e.target.value })}
          />
          <TextField
            margin="dense"
            id="description"
            label="Project Description"
            type="text"
            fullWidth
            variant="outlined"
            value={newProject.description}
            onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddProject}>Add</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminProjects;
